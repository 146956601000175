import styles from "./footer.module.scss";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className={styles.container}>
      <ul>
        {/* <li>T&C</li>
        <li>About</li> */}
        <li>
          <Link to={"/contact"}>Contact </Link>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
