import React, { useEffect, useState, useLayoutEffect } from "react";

import { Routes, Route, useParams, useLocation } from "react-router-dom";

import Header from "./components/Header";
import Gallery from "./components/Gallery";
import Highlight from "./components/Highlight";
import Filters from "./components/Filters";
import Footer from "./components/Footer";
import Establishment from "./components/Establishment";

import ContactPage from "./Pages/Contact";

import data, { mainCategories, locations as dataLocations } from "./data";

import "./App.scss";

const filterEstablishments = ({ establishments, categories, locations }) => {
  if (!categories.length && !locations.length) return establishments;
  if (!categories.length) {
    return establishments.filter(est => {
      return locations.includes(est.location);
    });
  }
  if (!locations.length) {
    return establishments.filter(est => categories.includes(est.category));
  }
  return establishments.filter(
    est => categories.includes(est.category) && locations.includes(est.location)
  );
};

function App() {
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [establishments, setEstablishments] = useState(data);

  const selectedCategories = categories
    .filter(cat => cat.selected)
    .map(selected => selected.value);

  const selectedLocations = locations
    .filter(loc => loc.selected)
    .map(sel => sel.value);

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    /* Reset categories selected on page change */
    setCategories(
      categories.map(cat => {
        return { ...cat, selected: false };
      })
    );

    setEstablishments(
      filterEstablishments({
        establishments: data,
        categories: selectedCategories,
        locations: selectedLocations
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // TODO format in JSOn data file?
  useEffect(() => {
    let tmpLoc = [],
      tmp = [];
    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries(mainCategories)) {
      tmp = [
        ...tmp,
        { label: value.label, value: value.value, selected: false }
      ];
    }
    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries(dataLocations)) {
      tmpLoc = [
        ...tmpLoc,
        { label: value.label, value: value.value, selected: false }
      ];
    }
    setLocations(tmpLoc);
    setCategories(tmp);
  }, []);

  useEffect(() => {
    setEstablishments(
      filterEstablishments({
        establishments: data,
        categories: selectedCategories,
        locations: selectedLocations
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories.length, selectedLocations.length]);

  const updateCategorySelection = event => {
    let tmp = [...categories];
    const applicable = categories.findIndex(
      cat => cat.value === event.target.id
    );
    tmp[applicable] = { ...tmp[applicable], selected: event.target.checked };
    setCategories(tmp);
  };

  const updateLocationSelection = e => {
    let tmp = [...locations];
    const applicable = locations.findIndex(loc => loc.value === e.target.id);
    tmp[applicable] = { ...tmp[applicable], selected: e.target.checked };
    setLocations(tmp);
  };

  return (
    <div className="app">
      <Routes>
        <Route
          path="/"
          element={
            <Display
              categories={categories}
              locations={locations}
              establishments={establishments}
              updateCategorySelection={updateCategorySelection}
              updateLocationSelection={updateLocationSelection}
            />
          }
        >
          <Route
            path="*"
            element={
              <Display
                categories={categories}
                locations={locations}
                establishments={establishments}
                updateCategorySelection={updateCategorySelection}
                updateLocationSelection={updateLocationSelection}
              />
            }
          />
        </Route>
        <Route
          path={`/establishment/:establishmentId`}
          element={<EstablishmentPage />}
        />
        <Route path={`/contact`} element={<ContactPage />} />
        <Route path={`/success`} element={<ContactPage success />} />
      </Routes>
    </div>
  );
}

const EstablishmentPage = () => {
  let { establishmentId } = useParams();
  const est = data.find(est => est.id === parseInt(establishmentId));

  return (
    <>
      <Header />
      <Establishment item={est} />
      <Footer />
    </>
  );
};

// TODO FIX prop drilling, move active filters into context.
const Display = ({
  categories,
  updateCategorySelection,
  updateLocationSelection,
  establishments,
  locations
}) => {
  return (
    <>
      <Header />
      <Highlight />
      <div className="app-content">
        <Filters
          categories={categories}
          locations={locations}
          updateCategorySelection={updateCategorySelection}
          updateLocationSelection={updateLocationSelection}
        />
        <Gallery items={establishments} />
      </div>
      <Footer />
    </>
  );
};

export default App;
