import laineThumbnail from "./images/thumbnails/laine_my_love.webp";
import stagThumbnail from "./images/thumbnails/stags_head.jpeg";
import windingThumbnail from "./images/thumbnails/winding_stair.jpeg";
import designShopThumbnail from "./images/thumbnails/design_shop.jpg";
import properOrderThumbnail from "./images/thumbnails/proper_order.jpeg";
import sixteen61Thumbnail from "./images/thumbnails/bar_1661.jpeg";
import oxmantownThumbnail from "./images/thumbnails/oxmantown.jpeg";
import kaphThumbnail from "./images/thumbnails/kalph.jpeg";
import threef3Thumbnail from "./images/thumbnails/3fe.jpeg";
import clementandpekoeThumbnail from "./images/thumbnails/clementandpekoe.jpeg";
import booksUpstairsThumbnail from "./images/thumbnails/booksupstairs.jpeg";
import scoutThumbnail from "./images/thumbnails/scout.jpeg";
import marrowboneThumbnail from "./images/thumbnails/marrowbone.jpeg";
import chaptersThumbnail from "./images/thumbnails/chapters.jpeg";
import gutterThumbnail from "./images/thumbnails/gutter.jpeg";
import hodgesfiggis from "./images/thumbnails/hodgesfigus.jpeg";
import kehoesThumbnail from "./images/thumbnails/keoghs.jpg";

import stags1 from "./images/squares/stags1.jpeg";
import stags2 from "./images/squares/stags2.jpeg";
import laine1 from "./images/squares/laine1.jpeg";
import laine2 from "./images/squares/laine2.jpeg";
import winding1 from "./images/squares/winding1.jpeg";
import winding2 from "./images/squares/winding2.jpeg";
import design1 from "./images/squares/design1.jpeg";
import design2 from "./images/squares/design2.jpeg";
import properorder1 from "./images/squares/properorder1.jpeg";
import a16611 from "./images/squares/a1661.jpeg";
import a16612 from "./images/squares/a16612.jpeg";

import oxman from "./images/squares/oxman.jpeg";
import kaph from "./images/squares/kaph.jpeg";
import books1 from "./images/squares/booksupstairs1.jpeg";
import books2 from "./images/squares/booksupstairs2.jpeg";

import chapters1 from "./images/squares/chapters1.jpeg";
import chapters2 from "./images/squares/chapters2.jpeg";

import marrow1 from "./images/squares/marrow1.jpeg";
import marrow2 from "./images/squares/marrow2.jpeg";

import poeke1 from "./images/squares/poeke.jpeg";
import poeke2 from "./images/squares/poeke2.jpeg";

import scout1 from "./images/squares/scout1.jpeg";
import scout2 from "./images/squares/scout2.jpeg";

import gutter1 from "./images/squares/gutter1.jpeg";
import gutter2 from "./images/squares/gutter2.jpeg";

import keheo1 from "./images/squares/keoghs1.jpeg";
import keheo2 from "./images/squares/keoghs2.jpeg";

export const mainCategories = {
  PUB: { value: "pub", label: "Pub/Bar" },
  COFFEE: { value: "coffee", label: "Coffee shop" },
  INDEPENDENT_RETAIL: {
    value: "indepentent_retail",
    label: "Small independent retail"
  },
  RETAIL: { value: "retail", label: "Retail" },
  // LARGE_RETAIL: { value: "large_retail", label: "Large retailer" },
  BOOK_SHOP: { value: "book_shop", label: "Bookshop" }
};

export const locations = {
  NORTH_SIDE: { value: "north_side", label: "North side" },
  SOUTH_SIDE: { value: "south_side", label: "South side" }
};

export const tags = {
  NORTHSIDE: {
    label: "North side",
    value: "northside",
    color: "#334964",
    textColor: "#334964",
    bgColor: "rgba(51,73,100,0.2)"
  },
  SOUTHSIDE: {
    label: "South side",
    value: "southside",
    color: "#334964",
    textColor: "#334964",
    bgColor: "rgba(51,73,100,0.2)"
  },
  INDEPENDENT: {
    label: "Independent",
    value: "independent",
    color: "#e00d7d",
    textColor: "#e00d7d",
    bgColor: "rgba(255,255,255)"
  },
  IRISH_OWNED: {
    label: "Irish owned",
    value: "irish_owned",
    textColor: "#387524",
    color: "#387524",
    bgColor: "rgba(56,117,36,0.2)"
  }
};

export const template = {
  id: 0,
  title: "",
  category: mainCategories.COFFEE.value,
  images: {},
  website: {
    url: "",
    label: ""
  },
  description: `<p></p>
      <p></p>`,
  address: {
    label: "",
    coords: []
  },
  instagram: { url: "", handle: "" },
  twitter: { url: "", handle: "" },
  location: locations.NORTH_SIDE.value,
  email: "info@stagshead.ie",
  phone: "+353 1 6793687",
  tags: [tags.NORTHSIDE, tags.INDEPENDENT, tags.IRISH_OWNED]
};

const items = [
  {
    id: 1,
    title: "Laine my love",
    description: `<p>A tiny but cute coffee shop not far from Connolly station. Serves a simple and delicious lunch menu with generously sized sambos.</p>
      <p>Also does decent coffee and various sweet treats. Can get very busy at peak lunchtime, with crowds from nearby offices. Not open at the weekend.</p>`,
    category: mainCategories.COFFEE.value,
    images: { thumbnail: laineThumbnail, first: laine1, second: laine2 },
    tags: [tags.NORTHSIDE, tags.INDEPENDENT],
    address: {
      label: "38 Joyce's Walk, Talbot St, Mountjoy, Dublin 1, D01 FV59",
      coords: [53.35161283754748, -6.2511359455355215]
    },
    instagram: {
      url: "https://www.instagram.com/lainemylove",
      handle: "lainemylove"
    },
    twitter: {
      url: "https://twitter.com/laine_my_love?lang=en",
      handle: "laine_my_love"
    },
    location: locations.NORTH_SIDE.value
  },
  {
    id: 2,
    title: "Stag's Head",
    description:
      "<p>A beautiful pub along Dublin's famous dame lane, there has been a pub on the site since 1770. The current pub, dating back to 1885 boasts possibly the city's best preserved Victorian facade.</p><p> The pub is set over three floors, with numerous cosy rooms. In fitting with the immediate area, prices are high and at busy times it can be difficult to get a seat. Frequently has live traditional Irish music and serves food seven days a week. Perfect for a Tuesday afternoon pint.</p>",
    website: { url: "https://stagshead.ie/", label: "stagshead.ie" },
    instagram: {
      url: "https://www.instagram.com/stags_head_dublin/?hl=en",
      handle: "stags_head_dublin"
    },
    email: "info@stagshead.ie",
    phone: "+353 1 6793687",
    address: {
      label: "1 Dame Ct, Dublin, D02 TW84, Ireland",
      coords: [53.343983603011175, -6.263637345535999]
    },
    category: mainCategories.PUB.value,
    images: { thumbnail: stagThumbnail, first: stags1, second: stags2 },
    location: locations.SOUTH_SIDE.value
  },
  {
    id: 3,
    title: "The Winding Stair",
    category: mainCategories.BOOK_SHOP.value,
    images: { thumbnail: windingThumbnail, first: winding1, second: winding2 },
    description: `<p>A quaint bookshop just off the river liffey and located under its sister restaurant, the winding stair is a small bookshop that packs a big punch. </p>
      <p>Contains a small but well curated selection of books with a mix of genres highlighted and tastefully presented in the store. There is also a nice children's section to the rear, as well as mix of bookmarks, cards and general literary knick knacks for sale. </p>
      <p>Stop here on your way southside before heading over the Ha'Penny bridge.</p>`,
    address: {
      label: "Ormond Quay Lower, Dublin 1, D01 R9Y5",
      coords: [53.346868423446296, -6.263611103208342]
    },
    website: {
      url: "https://winding-stair-bookshop.myshopify.com/",
      label: "winding-stair-bookshop.myshopify.com"
    },
    instagram: {
      url: "https://www.instagram.com/thewindingstairdublin/",
      handle: "thewindingstairdublin"
    },
    twitter: {
      url: "https://twitter.com/winding_stair?lang=en",
      handle: "winding_stair"
    },
    phone: "01 872 6576",
    email: "bookshop@winding-stair.com",
    location: locations.NORTH_SIDE.value
  },
  {
    id: 4,
    title: "Irish Design Shop",
    category: mainCategories.INDEPENDENT_RETAIL.value,
    images: { thumbnail: designShopThumbnail, first: design1, second: design2 },
    description: `<p>A small shop on the fabulous drury street, selling a selection of tastefully curated Irish goods.</p>
      <p>Established in 2008 by two Irish women, the store aims to showcase original design and craftsmanship.
      A perfect place to pick up a meaningful gift or to browse for some creative inspiration.</p>
      `,
    address: {
      label: "41 Drury St, Dublin",
      coords: [53.3427464, -6.2655276]
    },
    website: {
      url: "https://irishdesignshop.com/",
      label: "irishdesignshop.com"
    },
    instagram: {
      url: "https://www.instagram.com/irishdesignshop/?hl=en",
      handle: "irishdesignshop"
    },
    twitter: {
      url: "https://twitter.com/irish_design?lang=en",
      handle: "irish_design"
    },
    phone: "+353 (01) 679 8871",
    location: locations.SOUTH_SIDE.value,
    tags: [tags.SOUTHSIDE, tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 5,
    title: "Proper Order coffee co",
    website: {
      url: "https://www.properordercoffeeco.com/",
      label: "properordercoffeeco.com"
    },
    description: `<p>A quiet giant of a coffeeshop, just off smithfield square and the luas red line. Serves pastries from no messin bakery but get there early in the day to avoid pastry sell-out. Take away only, with no inside seats.</p>
      <p> Pricey coffee (and pastries) but worth it for some of the best coffee in town.</p>`,
    address: {
      label: "7 Haymarket, Smithfield, Dublin, D07 XW86, Ireland",
      coords: [53.3475415, -6.2820709]
    },
    instagram: {
      url: "https://www.instagram.com/proper_order_coffee_co/",
      handle: "proper_order_coffee_co"
    },
    email: "info@properordercoffeeco.com",
    category: mainCategories.COFFEE.value,
    images: { thumbnail: properOrderThumbnail, first: properorder1 },
    location: locations.NORTH_SIDE.value,
    tags: [tags.NORTHSIDE, tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 6,
    title: "Bar 1661",
    category: mainCategories.PUB.value,
    images: { thumbnail: sixteen61Thumbnail, first: a16611, second: a16612 },
    website: {
      url: "https://bar1661.ie/",
      label: "bar1661.ie"
    },
    description: `<p>A modern bar, serving some of the city's best cocktails.</p>
      <p>Located in a surprisingly quiet part of town, considering it's proximity to Capel street.</p>`,
    address: {
      label: "1-5 Green St, Rotunda, Dublin, D07 YT6H",
      coords: [53.3493071, -6.2729775]
    },
    instagram: {
      url: "https://www.instagram.com/1661bar/",
      handle: "1661bar"
    },
    email: "hello@bar1661.ie",
    phone: "+353 1 878 8706",
    location: locations.NORTH_SIDE.value,
    tags: [tags.NORTHSIDE, tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 7,
    title: "Oxmantown",
    category: mainCategories.COFFEE.value,
    images: { thumbnail: oxmantownThumbnail, first: oxman },
    website: {
      url: "https://www.oxmantown.com/",
      label: "oxmantown.com"
    },
    description: `<p>A cosy coffee shop, serving great coffee and delicious sandwiches. Take away only.</p>
      <p>Located not far from capel street, yet in a quiet part of the city, stop in for a nice on your way to the four courts or as you head into town. We recommend an oat latte. </p>`,
    address: {
      label: "16 Mary's Abbey, North City, Dublin 7",
      coords: [53.3471512, -6.2701709]
    },
    instagram: {
      url: "https://www.instagram.com/oxmantown/",
      handle: "oxmantown"
    },
    twitter: { url: "https://twitter.com/oxmantown", handle: "oxmantown" },
    location: locations.NORTH_SIDE.value,
    tags: [tags.NORTHSIDE, tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 8,
    title: "Kaph",
    category: mainCategories.COFFEE.value,
    images: { thumbnail: kaphThumbnail, first: kaph },
    location: locations.SOUTH_SIDE.value,
    tags: [tags.SOUTHSIDE, tags.INDEPENDENT],
    website: {
      url: "https://kaph.ie/",
      label: "kaph.ie"
    },
    description: `<p>A beautiful coffee shop on the ever bustling Drury street. Serves great coffee with other coffee related accessories, including beans, available for purchase.</p>
        <p>Some seating upstairs but location is normally busy, even on off peak hours. Open unusally late for an independent coffee shop, Kalph is perfect for those seeking a late afternoon or early evening coffee.</p>`,
    address: {
      label: "31 Drury St. Dublin 2",
      coords: [53.3425972, -6.2658149]
    },
    instagram: { url: "https://www.instagram.com/kaphsc", handle: "kaphsc" },
    email: "info@kaph.ie",
    phone: "+353 (0) 1 613 9030"
  },
  {
    id: 9,
    title: "3fe",
    website: "https://3fe.com/",
    about: "",
    instagram: "https://www.instagram.com/3fecoffee/",
    category: mainCategories.COFFEE.value,
    images: { thumbnail: threef3Thumbnail },
    location: locations.SOUTH_SIDE.value,
    hidden: true
  },
  {
    id: 10,
    title: "Clement and pekoe",
    category: mainCategories.COFFEE.value,
    images: {
      thumbnail: clementandpekoeThumbnail,
      first: poeke1,
      second: poeke2
    },
    location: locations.SOUTH_SIDE.value,
    tags: [tags.SOUTHSIDE, tags.INDEPENDENT],
    website: {
      url: "https://clementandpekoe.com/",
      label: "clementandpekoe.com"
    },
    description: `<p>A speciality tea shop, which also serves high quality coffee on South William Street.</p>
        <p>Plenty of indoor seating means it a nice place to catch up with a friend over a cuppa or to read a book. Laptop use forbidden during peak hours.</p>`,
    address: {
      label: "50 William St S, Dublin 2",
      coords: [53.34163735978738, -6.262799245079202]
    },
    instagram: {
      url: "https://www.instagram.com/clementpekoe",
      handle: "clementpekoe"
    },
    twitter: {
      url: "https://twitter.com/ClementandPekoe",
      handle: "ClementandPekoe"
    },
    email: "info@clementandpekoe.com"
  },
  {
    id: 11,
    title: "Scout Design Store",
    website: { url: "https://scoutdublin.com", label: "scoutdublin.com" },
    instagram: {
      url: "https://www.instagram.com/scoutdesignstore",
      handle: "scoutdesignstore"
    },
    category: mainCategories.INDEPENDENT_RETAIL.value,
    images: { thumbnail: scoutThumbnail, first: scout1, second: scout2 },
    location: locations.SOUTH_SIDE.value,
    description: `<p>A store containing a selection of expertly curated beautiful bespoke and high quality goods. The store itself has a very charming interior and that is worth a visit itself to peruse.</p>
        <p>The goods for sale are pricey, as one would expect for high quality items. However there are a broad range of items from cards and candles, to jackets and skincare, that should fit all budgets. Perfect for anyone looking to buy a gift or to treat themselves to something nice. </p>`,
    address: {
      label: "5 Essex St W, Temple Bar, Dublin 2",
      coords: [53.34471214173803, -6.269151276836527]
    },
    email: "hello@scoutdublin.com",
    phone: "01 6778846"
  },
  {
    id: 12,
    title: "Books Upstairs",
    category: mainCategories.BOOK_SHOP.value,
    images: {
      thumbnail: booksUpstairsThumbnail,
      first: books1,
      second: books2
    },
    location: locations.SOUTH_SIDE.value,
    website: {
      url: "https://booksupstairs.ie/",
      label: "booksupstairs.ie"
    },
    description: `<p>A giant of a bookstore, both literally and figuratively, booksupstairs is a dublin institution for local booklovers.</p>
        <p>With a beautifully decorated shop front and situated in an old georgian building just off the quays, booksupstairs has a lovely curation of books of all genres. The bookstore is split across three floors, with a generously stocked second hand section on the upper floors.</p>
        <p>There is also a beautiful coffee shop with limited opening hours on the first floor of the building.</p>`,
    address: {
      label: "17 D'Olier Street, Dublin 2, Ireland",
      coords: [53.34630534571147, -6.256692646049975]
    },
    instagram: {
      url: "https://www.instagram.com/booksupstairs/",
      handle: "booksupstairs"
    },
    twitter: {
      url: "https://twitter.com/BooksUpstairs",
      handle: "BooksUpstairs"
    },
    email: "shop@booksupstairs.ie",
    phone: "01-6778566",
    tags: [tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 13,
    title: "Chapters",
    description: `<p>A mamoth of an independent bookshop in the north of the city, Chapters has recently been purchased by new owners after it announced it was closing down.</p>
        <p>Chapters has a hugh selection of books, and here you can find some of the greatest value in new books to be bought in the city. It also has the greatest selection of second hand books for purchase.</p>
        <p> The shop is large so it doesn't have the ambience of some other popular bookshops in the city. It has recently added a coffee shop and plant store on its upper floor.</p>`,
    website: {
      url: "https://chaptersbookstore.com/",
      label: "chaptersbookstore.com"
    },
    instagram: {
      url: "https://www.instagram.com/chaptersbookstore/",
      handle: "chaptersbookstore"
    },
    twitter: {
      url: "https://twitter.com/chaptersbooks",
      handle: "chaptersbooks"
    },
    category: mainCategories.BOOK_SHOP.value,
    images: {
      thumbnail: chaptersThumbnail,
      first: chapters1,
      second: chapters2
    },
    location: locations.NORTH_SIDE.value,
    address: {
      label: "Ivy Exchange, Parnell St, Rotunda, Dublin 1",
      coords: [53.351662570086766, -6.263973932044276]
    },
    phone: "+353 (0)1 879 2700",
    email: "shop@chaptersbookstore.com",
    tags: [tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 14,
    title: "Marrowbone bookshop",
    description: `<p>A beautiful second hand bookstore in the heart of the liberties.</p>
        <p>Worth a visit for a pic of the shop front alone, this bookshop contains a collection of well priced used books, mostly paper back and mostly fiction.</p>
        `,
    website: { url: "https://marrowbone.ie/", label: "marrowbone.ie" },
    instagram: {
      url: "https://www.instagram.com/marrowbonebooks/?hl=en",
      handle: "marrowbonebooks"
    },
    address: {
      abel: "78 The Coombe, The Liberties, Dublin 8",
      coords: [53.33995312984065, -6.278348105062714]
    },
    category: mainCategories.BOOK_SHOP.value,
    images: { thumbnail: marrowboneThumbnail, first: marrow1, second: marrow2 },
    location: locations.SOUTH_SIDE.value,
    phone: "+353 1 551 0495",
    email: "shop@marrowbone.ie",
    twitter: {
      url: "https://twitter.com/marrowbonebooks",
      handle: "marrowbonebooks"
    },
    tags: [tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 15,
    title: "The Gutter bookshop",
    description: `<p>A bright spot in a quiet enclave in temple bar (if such thing exists), this bookstore is the big sister bookstore of the Dalkey store of the same name.</p>
        <p>Stop in and view their wide selection, the relaxed atmosphere inside allows for enjoyable, peaceful browsing. </p>
        <p>The name of the store is taken from a well known Oscar Wilde quote and thankfully does not refer to the store's immediate location, which is a serene and quaint part of template bar with lots of small independent stores.</p>`,
    category: mainCategories.BOOK_SHOP.value,
    images: { thumbnail: gutterThumbnail, first: gutter1, second: gutter2 },
    location: locations.SOUTH_SIDE.value,
    website: {
      url: "https://gutterbookshop.com/",
      label: "gutterbookshop.com"
    },
    instagram: {
      url: "https://www.instagram.com/gutterbookshop",
      handle: "gutterbookshop"
    },
    twitter: {
      url: "https://twitter.com/chaptersbooks",
      handle: "chaptersbooks"
    },
    address: {
      label: "Cow's Ln, Temple Bar, Dublin 8",
      coords: [53.344817474155946, -6.268786530190903]
    },
    phone: "(01) 679 9206",
    email: "info@gutterbookshop.com",
    tags: [tags.INDEPENDENT, tags.IRISH_OWNED]
  },
  {
    id: 16,
    title: "Hodges Figgis",
    category: mainCategories.BOOK_SHOP.value,
    images: { thumbnail: hodgesfiggis },
    location: locations.SOUTH_SIDE.value,
    description: `<p>A beautiful and immense bookshop in the heart of Dublin city. Sadly, despite appearances this is not an independent store.</p>
    <p>Claiming to date back to 1768,the original store was bought and now owned by British Giant Waterstones.</p>
    <p>The store maintains it's charm however, and despite the massive building, it's interior is cosy and welcoming. They excel at hiring and training staff as everyone there is highly knowledgeable and extremely helpful. </p>
    <p>They also host regular author readings, book signings, and literary events, making it a regular hotspot for Dublin’s literary community.
        </p>
        `,
    website: {
      url: "https://www.hodgesfiggis.ie/",
      label: "hodgesfiggis.com"
    },
    instagram: {
      url: "https://www.instagram.com/hodgesfiggis/",
      handle: "hodgesfiggis"
    },
    twitter: {
      url: "https://twitter.com/hodges_figgis",
      handle: "hodges_figgis"
    },
    address: {
      label: "56-58 Dawson St, Dublin 2",
      coords: [53.342378687118334, -6.257783365103069]
    },
    phone: "00 35316774754",
    email: "enquiries@hodgesfiggis.ie"
  },
  {
    id: 17,
    title: "Kehoes",
    category: mainCategories.PUB.value,
    images: { thumbnail: kehoesThumbnail, first: keheo1, second: keheo2 },
    website: {
      url: "https://kehoesdublin.ie/",
      label: "kehoesdublin.ie"
    },
    description: `<p>A charming and traditional pub just off Grafton street, Kehoes is a real delight in the heart of Dublin 2.</p>
        <p>The old victorian interior has been preserved, boasting traditional mahogany wood throughout coupled with stained glass saloon doors. Lauded by many as the best pub in Dublin.</p>`,
    address: {
      label: "9 Anne St S, Dublin 2",
      coords: [53.34125995678798, -6.259438432045001]
    },
    instagram: {
      url: "https://www.instagram.com/kehoesdublin",
      handle: "kehoesdublin"
    },
    location: locations.SOUTH_SIDE.value,
    email: "info@kehoesdublin.ie",
    phone: "+353 1 6778312",
    tags: [tags.SOUTHSIDE]
  }
];

export default items.filter(item => !item.hidden);
