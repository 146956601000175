import { Link } from "react-router-dom";

import styles from "./card.module.scss";

function Card({ item }) {
  return (
    <Link to={`/establishment/${item.id}`} className={styles.link}>
      <div className={styles.container}>
        <h4 className={styles.title}>{item.title}</h4>
        {item.images?.thumbnail && (
          <img
            className={styles.thumbnail}
            src={item.images.thumbnail}
            alt="establishment thumbail"
          />
        )}
      </div>
    </Link>
  );
}

export default Card;
