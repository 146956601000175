import styles from "./gallery.module.scss";

import Card from "../Card";

function Gallery({ items }) {
  return (
    <div className={styles.container}>
      {items.map(item => {
        return <Card key={item.title} item={item} />;
      })}
    </div>
  );
}

export default Gallery;
