import styles from "./highlight.module.scss";

function Highlight() {
  return (
    <div className={styles.container}>
      <p>Discover some of the best Dublin city has to offer</p>
    </div>
  );
}

export default Highlight;
