import styles from "./contact.module.scss";

import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function Contact({ success = false }) {
  const navigate = useNavigate();
  const handleSubmission = e => {
    e.preventDefault();
    const formElement = document.getElementById("dublin-contact");
    const formData = new FormData(formElement);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => navigate("/success"))
      .catch(error => {
        alert(error);
      });
  };

  if (success) {
    return (
      <div className={styles.container}>
        <Header />
        <div className={styles.success}>
          <h1>Contact Us</h1>
          <p>Thanks for getting in touch! We'll get back to you soon.</p>
        </div>

        <Footer />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.message}>
        <h1>Contact Us</h1>
        <p>
          Have location suggestions or just like giving feedback? Drop us a line
          here and we'll get back to you soon!
        </p>
      </div>
      <form
        name="dublin-contact"
        id="dublin-contact"
        method="POST"
        data-netlify="true"
        action="/success"
        onSubmit={handleSubmission}
      >
        <input type="hidden" name="form-name" value="dublin-contact" />
        <div className={styles.inputContainer}>
          <label>Your Email</label>
          <input required type="email" name="email" />
        </div>
        <div className={styles.inputContainer}>
          <label>Your Message</label>
          <textarea required rows="5" name="message"></textarea>
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default Contact;
