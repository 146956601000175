import { Link } from "react-router-dom";

import { MapContainer, TileLayer, Marker } from "react-leaflet";
import styles from "./establishment.module.scss";

function Establishment({ item }) {
  return (
    <div className={styles.page}>
      <Link className={styles.home} to={`/`}>
        Back
      </Link>
      <div className={styles.container}>
        <div className={styles.images}>
          <div className={styles.squares}>
            <img src={item.images.thumbnail} alt="establishment main" />
            {item.images?.first && (
              <img src={item.images.first} alt="establishment small" />
            )}
            {item.images?.second && (
              <img src={item.images.second} alt="establishment small" />
            )}
          </div>
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>{item.title}</h2>
          {item.description && (
            <p
              dangerouslySetInnerHTML={{ __html: item.description }}
              className={styles.description}
            ></p>
          )}
          {item.tags && (
            <div className={styles.tags}>
              {item.tags.map(tag => {
                return (
                  <div
                    key={tag.value}
                    className={styles.tag}
                    style={{
                      borderColor: tag.color,
                      backgroundColor: tag.bgColor,
                      color: tag.textColor
                    }}
                  >
                    {tag.label}
                  </div>
                );
              })}
            </div>
          )}
          <>
            <h4>Company details</h4>
            {item.instagram && (
              <div className={styles.details}>
                <div>Instagram</div>
                <a target="_blank" rel="noreferrer" href={item.instagram.url}>
                  @{item.instagram.handle}
                </a>
              </div>
            )}
            {item.website && (
              <div className={styles.details}>
                <div>Website</div>
                <a target="_blank" rel="noreferrer" href={item.website.url}>
                  {item.website.label}
                </a>
              </div>
            )}
            {item.email && (
              <div className={styles.details}>
                <div>Email</div>
                <a href={`mailto:${item.email}`}>{item.email}</a>
              </div>
            )}
            {item.twitter && (
              <div className={styles.details}>
                <div>Twitter</div>
                <a target="_blank" rel="noreferrer" href={item.twitter.url}>
                  @{item.twitter.handle}
                </a>
              </div>
            )}
            {item.phone && (
              <div className={styles.details}>
                <div>Phone</div>
                <span>{item.phone}</span>
              </div>
            )}
          </>
          <>
            <h4>Location</h4>
            {item.address && (
              <>
                <div className={styles.details}>
                  <div>Address</div>
                  <span>{item.address.label}</span>
                </div>

                <div className={styles.map} id="map">
                  <MapContainer
                    center={[53.3462763, -6.2645602]}
                    zoom={14}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {item.address.coords && (
                      <Marker position={item.address.coords}></Marker>
                    )}
                  </MapContainer>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export default Establishment;
