import { Link } from "react-router-dom";

import styles from "./header.module.scss";

function Header() {
  return (
    <header className={styles.container}>
      <Link to={`/`}>
        <div className={styles.headerLogo}>
          <span>Dublincity</span>
          <div>gems</div>
        </div>
      </Link>
    </header>
  );
}

export default Header;
