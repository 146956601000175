import React, { useState } from "react";
import styles from "./filters.module.scss";

function Filters({
  categories,
  updateCategorySelection,
  locations,
  updateLocationSelection
}) {
  const isMobile = window.innerWidth < 600;
  const [categoriesExpanded, setCategoriesExpanded] = useState(!isMobile);
  const [locationsExpanded, setLocationsExpanded] = useState(!isMobile);

  const toggleCategories = () => setCategoriesExpanded(!categoriesExpanded);
  const toggleLocations = () => setLocationsExpanded(!locationsExpanded);

  return (
    <div className={styles.filters}>
      <div className={styles.container}>
        <h3 onClick={toggleCategories}>
          Categories
          <span className={styles.mobileChevron}>
            {categoriesExpanded ? "↑" : "↓"}
          </span>
        </h3>
        {categoriesExpanded &&
          categories.map(category => {
            return (
              <div key={category.value} className={styles.category}>
                <input
                  type="checkbox"
                  id={category.value}
                  name={category.value}
                  onChange={updateCategorySelection}
                  select={category.selected.toString()}
                />
                <label htmlFor={category.value}>{category.label}</label>
              </div>
            );
          })}
      </div>
      <div className={styles.container}>
        <h3 onClick={toggleLocations}>
          Location
          <span className={styles.mobileChevron}>
            {locationsExpanded ? "↑" : "↓"}
          </span>
        </h3>
        {locationsExpanded &&
          locations.map(loc => {
            return (
              <div key={loc.value} className={styles.location}>
                <input
                  type="checkbox"
                  id={loc.value}
                  name={loc.value}
                  onChange={updateLocationSelection}
                  select={loc.selected.toString()}
                />
                <label htmlFor={loc.value}>{loc.label}</label>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Filters;
